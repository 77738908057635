<template>
	<v-container fluid class="px-5">
		<ViewTitles class="ml-1 mb-4">
			<h2 class="primary--text" slot="title">
				Automação de Relatórios Cliente -
				{{
					$router.currentRoute.query.actionType === "insert"
						? "Nova Configuração"
						: "Editar"
				}}
			</h2>
		</ViewTitles>

		<DefaultForm
			ref="DefaultForm"
			:refForm="'form'"
			:formConfig="formConfig"
			:model="form"
		/>

		<v-footer class="my-5 pr-3">
			<v-row class="d-flex justify-end">
				<v-col v-bind="propsColFooter">
					<v-btn
						x-large
						block
						outlined
						color="textPrimary"
						@click="onClickBack()"
					>
						Voltar
					</v-btn>
				</v-col>
				<v-col v-bind="propsColFooter" class="pr-0">
					<v-btn
						x-large
						block
						:loading="loadingForm"
						color="textPrimary"
						class="white--text"
						@click="saveForm()"
					>
						Salvar
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
    <SnackbarCustomize ref="SnackbarCustomize" />
	</v-container>
</template>
<script>
import Rules from "@/shared/validators/formRules"
import ViewTitles from "@/components/PageTitles/ViewTitles.vue"

import VerifyRoutesMixin from "@/shared/mixins/routeManagement/verifyRoutesMixin";
import MovementRecordsMixin from "@/shared/mixins/movementRecords/movementRecordsMixin";
import ReportAutomationMixin from "@/shared/mixins/reportAutomation/reportAutomationMixin";
import FinancialGroupsMixin from "@/shared/mixins/sdi/financialGroupsMixin";

import CustomerReportAutomationService from "@/services-http/sdi/CustomerReportAutomationService";
import CollectorService from '@/services-http/sdi/CollectorService';

import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";

import { mapMutations, mapGetters } from "vuex";
import DefaultForm from "@/components/Default/DefaultForm/DefaultForm.vue";

export default {
	name: "CustomerReportAutomationForm",

	components: {
		ViewTitles,
    	SnackbarCustomize,
			DefaultForm,
	},

	props: {
		subtitle: {
			type: String,
			default: ""
		}
	},

	data: () => ({
		loadingFinancialGroups: false,
		showPassword: false,
		loadingForm: false,
		isRHProtegido: false,

    	customerReportService: null,

		rule: null,

		form: {},

		propsCol: {
			class: "text-subtitle-1 primary--text font-weight-medium",
			cols: "12",
			md: "4",
			lg: "4",
			xl: "3"
		},
		propsCard: {
			class: "mb-5 rounded-lg pa-6"
		},
		propsCardTitle: {
			class: "lable mb-5 title"
		},
		propsAutoComplete: {
			class: "font-weight-regular",
			itemText: "name",
			itemValue: "id",
			outlined: true,
			placeholder: "Selecione",
			color: "textPrimary",
			validateOnBlur: true
		},
		propsTextField: {
			class: "font-weight-regular",
			outlined: true,
			color: "textPrimary"
		},

		propsColFooter: {
			cols: "12",
			xs: "12",
			sm: "6",
			md: "2",
			lg: "2",
			xl: "2"
		},

		financialGroups: [],
		collectors: [],
		loadingCollectors: false
	}),

	mixins: [
		MovementRecordsMixin,
		ReportAutomationMixin,
		VerifyRoutesMixin,
		FinancialGroupsMixin
	],

	methods: {
		...mapMutations({
			cleanForm: 'customer-report-automation/cleanForm',
			setFormSelected: 'customer-report-automation/setFormSelected',
		}),

		handlePageRefresh() {
      sessionStorage.setItem('customer-form', JSON.stringify(this.form))
    },

    setSessionStoreData() {
      if (sessionStorage.getItem('customer-form')) {
        this.setFormSelected(JSON.parse(sessionStorage.getItem('customer-form')));
        sessionStorage.removeItem('customer-form')
      }
    },

		onClickBack() {
			this.cleanForm()
			this.redirectRouter("CustomerReportAutomation", {
				isRHProtegido: this.isRHProtegido
			})
		},

		async saveForm() {
			if (this.$refs.DefaultForm.$refs.form.validate()) {
				this.loadingForm = true
        if(this.isInsert()){
					delete this.form.collectorId;
          await this.customerReportService.SaveForm(this.form).then((reponse) => {
            if (this.form && this.form.id) {
              this.$refs.SnackbarCustomize.open('success', 'Configuração atualizada com sucesso.');
            } else {
              this.$refs.SnackbarCustomize.open('success', 'Configuração criada com sucesso.');
            }
						this.form = this.getInitialForm();
						this.onClickBack();
            this.loadingForm = false
          }).catch((err) => {
            this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao concluir o processo.');
            this.loadingForm = false
          });
        } else {
          await this.customerReportService.EditForm(this.form).then((reponse) => {
            if (this.form && this.form.id) {
              this.$refs.SnackbarCustomize.open('success', 'Configuração atualizada com sucesso.');
            } else {
              this.$refs.SnackbarCustomize.open('success', 'Configuração criada com sucesso.');
            }
						this.form = this.getInitialForm();
						this.onClickBack();
            this.loadingForm = false
          }).catch((err) => {
            this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao concluir o processo.');
            this.loadingForm = false
          });
        }
				this.loadingForm = false
				return
			}
      this.$refs.SnackbarCustomize.open('error', 'Formulário com campos inválidos');
		},

    isInsert(){
      return this.$router.currentRoute.query.actionType === "insert"
    },

		getInitialForm() {
			return {
			financialGroupId: null,
			dataModel: null,
			reportLayout: null,
			reportQueryType: "DEFAULT",
			reportIntegrationType: "SFTP",
			cronExpression: null,
			showProtectedHr: false,
			integrationUrl: null,
			integrationPort: null,
			integrationUser: null,
			integrationPassword: null,
			integrationDirectory: null,
			integrationFolderHierarchy: "YEAR_MONTH",
			active: true
			}
		},
		async loadCollectorByFinancialGroupId() {
			const params = {
				financialGroupId: this.form.financialGroupId,
				type: 'FTP'
			};
			this.collectors = [];
			this.loadingCollectors = true;
        	await this.collectorService.FindAllByFilters(params).then((response) => {
				if (response && response.data && response.data.length > 0) {
					response.data.forEach((element) => 
						element.configs.forEach((config) => {
							if(config.type === 'FTP') {
								this.collectors.push(config)
							}							
						})
					);
				}
			}).catch((error) => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os coletores.');
			}).finally(() => {
				this.loadingCollectors = false;
			});
		},
		async loadIntegrationConfigByCollector(collectorId) {
			this.collectors.forEach((element) => {
				if(element.id === collectorId) {
					this.form.integrationUrl = element.ftpParameter.url;
					this.form.integrationPort = element.ftpParameter.port;
					this.form.integrationUser = element.ftpParameter.user;
					this.form.integrationPassword = element.ftpParameter.password;
				}
			});
			
		}
	},

	computed: {
		...mapGetters({
			customerForm: 'customer-report-automation/customerForm'
		}),

		formConfig() {
      return [
        {
					title: "Parâmetros Geráis",
					attrs: this.propsCard,
					fields: [
						{
							type: 'autocomplete',
							name: 'financialGroupId',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsAutoComplete,
								label: 'Grupo Financeiro*',
								items: this.financialGroups,
								loading: this.loadingFinancialGroups,
								rules: [this.rule.required],
							},
							on: {
								'change': this.loadCollectorByFinancialGroupId
							}
						},
						{
							type: 'autocomplete',
							name: 'dataModel',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsAutoComplete,
								label: 'Modelo de Dados*',
								items: this.getDataModelTypes,
								rules: [this.rule.required],
							},
						},
						{
							type: 'autocomplete',
							name: 'reportLayout',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsAutoComplete,
								label: 'Layout de Relatório*',
								items: this.getReportLayoutTypesByDataModel(this.form.dataModel),
								rules: [this.rule.required],
							},
						},
						/*
							According to activity XiPP-7957, the reportQueryType field
							will initially have a fixed initial value.

							Please remove this comment when adjustments to this field are made.
						*/
						{
							type: 'textfield',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsTextField,
								label: 'Nome da Consulta*',
								readonly: true,
							},
							value: "Movimentações criadas nos últimos 30 dias por Grupo Financeiro",
						},
						/*
							According to activity Xipp-7957, the reportIntegrationType field
							will initially have a fixed initial value as SFTP.

							Please remove the comment when FTP implementation is carried out.
						*/
						{
							type: 'autocomplete',
							name: 'reportIntegrationType',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsAutoComplete,
								label: 'Tipo de integração*',
								items: this.getIntegrationTypes,
								rules: [this.rule.required],
							},
						},
						{
							type: 'textfield',
							name: 'cronExpression',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsTextField,
								label: 'Periodicidade (Cron Expression)*',
								placeholder: "0*/?",
								rules: [this.rule.required],
								validateOnBlur: true,
							},
						},
						{
							type: 'autocomplete',
							col: {
								attrs: this.propsCol,
							},
							name: 'showProtectedHr',
							attrs: {
								...this.propsAutoComplete,
								label: 'Exige RH Protegido*',
								items: [
									{ name: 'Sim', id: true },
									{ name: 'Não', id: false }
								],
								rules: [this.rule.isBoolean],
							},
						},
					]
				},
				{
					title: "Tipo de Integração: SFTP",
					attrs: this.propsCard,
					fields: [
						{
							type: 'autocomplete',
							name: 'collectorId',
							col: {
								cols: 12,
							},
							tooltip: {
								attrs: {
									bottom: true,
									color: 'primary',
								},
								description: "Selecione uma opção para carregar os dados dos coletores",
							},
							section: {
								attrs: {
									style: this.$vuetify.breakpoint.lg
										? 'width: 33.3333333333%; flex: 0 0 33.3333333333%; padding: 0 18px 0 0;'
										: 'width: 25%; flex: 0 0 25%; padding: 0 18px 0 0;',
								}
							},
							attrs: {
								...this.propsAutoComplete,
								class: 'mb-3',
								label: 'Coletor',
								hideDetails: true,
								items: this.collectors,
								loading: this.loadingCollectors,
							},
							on: {
								'change': (collectorId) => this.loadIntegrationConfigByCollector(collectorId)
							}
						},
						{
							type: 'textfield',
							name: 'integrationUrl',
							col: {
								attrs: this.propsCol,
							},
							attrs: {
								...this.propsTextField,
								label: 'URL (Hostname)*',
								validateOnBlur: true,
								placeholder: "Informe a URL",
								rules: [this.rule.required],
							},
						},
						{
							type: 'textfield',
							col: {
								attrs: this.propsCol,
							},
							name: 'integrationPort',
							attrs: {
								...this.propsTextField,
								label: 'Porta*',
								validateOnBlur: true,
								placeholder: "Informe a porta",
								vMask: "#####",
								rules: [this.rule.required],
							},
						},
						{
							type: 'textfield',
							col: {
								attrs: this.propsCol,
							},
							name: 'integrationUser',
							attrs: {
								...this.propsTextField,
								label: 'Usuário*',
								validateOnBlur: true,
								placeholder: "Informe o nome da consulta",
								rules: [this.rule.required],
							},
						},
						{
							type: 'textfield',
							col: {
								attrs: this.propsCol,
							},
							name: 'integrationPassword',
							attrs: {
								...this.propsTextField,
								appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
								type: this.showPassword ? 'text' : 'password',
								label: 'Senha*',
								validateOnBlur: true,
								placeholder: "Informe o nome da consulta",
								rules: [this.rule.required],
							},
							on: {
								'click:append': () => (this.showPassword = !this.showPassword)
							},
						},
						{
							type: 'textfield',
							col: {
								attrs: this.propsCol,
							},
							name: 'integrationDirectory',
							attrs: {
								...this.propsTextField,
								label: 'Diretório Raiz*',
								validateOnBlur: true,
								placeholder: "Informe o caminho do arquivo",
								rules: [this.rule.required],
							},
						},
						{
							type: 'autocomplete',
							col: {
								attrs: this.propsCol,
							},
							name: 'integrationFolderHierarchy',
							attrs: {
								...this.propsAutoComplete,
								label: 'Hierarquia Pasta*',
								items: this.getFolderHierarchyTypes,
								rules: [this.rule.required],
							},
						},
					]
				}
      ]
    },
	},
	async created() {
		this.rule = new Rules();
		this.form = this.getInitialForm();
    	this.customerReportService = new CustomerReportAutomationService();
    	this.isRHProtegido = this.$route.query.isRHProtegido;
		this.collectorService = new CollectorService();
		await this.getFinancialGroups();
	},

	beforeMount() {
		window.addEventListener('beforeunload', this.handlePageRefresh);
    	this.setSessionStoreData();
	},

	async mounted() {
		if (this.$router.currentRoute.query.actionType === "update" && Boolean(this.$router.currentRoute.query.id)) {
			this.form = JSON.parse(JSON.stringify(this.customerForm));
			await this.loadCollectorByFinancialGroupId()
		}
	},

	beforeDestroy() {
    	window.removeEventListener('beforeunload', this.handlePageRefresh);
  	}
}
</script>
