import MovementRecordTypeService from "@/services-http/sdi/MovementRecordTypeService";

export default {
  data: () => ({
    movementTypeService: null,
    movementTypes: [],
    filterFileMovements: {
      onlyFtpIntegration: false,
      protocolNumber: '',
      createdBy: '',
      financialGroup: '',
      receivingDateStart: '',
      receivingDateEnd: '',
      status: '',
      withErrors:''
    },
    filterShippingBatches: {
      id: '',
      insuranceCarrierId: [],
      createdAtStart: '',
      createdAtEnd: '',
      sendAtStart: '',
      sendAtEnd: '',
      protocolNumber: '',
      status: '',
      createdBy: '',
      batchType: '',
      movementInsuranceCarrierId: '',
      onlyManualProtocol: false,
      financialGroupIds: [],
    },
    filterMovements: {
      protocolNumber: '',
      movementRecordId: '',
      movementInsuranceCarrierId: '',
      fileDataId: '',
      financialGroup: '',
      contract: '',
      subContract: '',
      name: '',
      document: '',
      nameDependent: '',
      documentDependent: '',
      movementType: [],
      status: [],
      dateType: 'isMovementDate',
      receivingDateStart: '',
      receivingDateEnd: '',
      insuranceCarrierIds: [],
      onlyManualProtocol: false,
      onlyErrors: false,
      appOrigin: 'allChannels',
      pendingMovements: false,
      internalCriticism: 'both',
      withDependents: false,
    },
    filterMyReports: {
      financialGroupId: '',
      reportLayout: '',
      status: '',
      receivingDateStart: '',
      receivingDateEnd: '',
    }
  }),

  methods: {
    loadMovementTypes() {
      this.movementTypeService.FindAll().then((response) => {
        this.movementTypes = response.data.sort((a, b) => (a.description > b.description ? 1 : -1));
      });
    },

    getMovementTypeDescription(movementType) {
      if (this.movementTypes.length === 0) return '';

      const movementTypeIndex = this.movementTypes.findIndex((el) => el.code === movementType);

      return this.movementTypes[movementTypeIndex].description;
    },
  },

  created() {
    this.movementTypeService = new MovementRecordTypeService();
  },
};
