<template>
  <v-form
    :ref="refForm">
    <v-row>
      <v-col
        v-for="(main, mainIndex) in formConfig"
        cols="12"
        :key="`main-${mainIndex}`">
        <v-card
          v-bind="main.attrs">
          <label
            class="title">
            {{ main.title }}
          </label>

          <v-row>
            <v-col
              v-for="(field, fieldIndex) in main.fields"
              :cols="field.col.cols || 12"
              :key="`field-${fieldIndex}`"
              v-bind="field.col.attrs"
              v-on="field.col.on">
              <v-tooltip
                :disabled="'tooltip' in field ? !field.tooltip.description : true"
                v-bind="'tooltip' in field ? field.tooltip.attrs : false"
                bottom>
                <template
                  v-slot:activator="{ on, attrs }">
                  <section
                    v-on="on"
                    v-bind="'section' in field ? field.section.attrs : false">
                    <component
                      v-if="model[field.name] !== undefined && field.model === undefined"
                      v-model="model[field.name]"
                      :is="getInput(field.type)"
                      v-bind="field.attrs"
                      v-on="field.on"
                      v-mask="'vMask' in field.attrs ? field.attrs.vMask : null">
                      <slot name="slot"></slot>
                    </component>

                    <component
                      v-else
                      :is="getInput(field.type)"
                      v-bind="field.attrs"
                      v-on="field.on"
                      :value="field.value"
                      v-mask="'vMask' in field.attrs ? field.attrs.vMask : null">
                      <slot name="slot"></slot>
                    </component>
                  </section>
                </template>
                <span>
                  {{ 'tooltip' in field ? field.tooltip.description : '' }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  VSelect,
  VCombobox,
  VTextField,
  VAutocomplete,
  VFileInput,
  VCheckbox,
  VRadio,
} from 'vuetify/lib';

export default {
  name: "DefaultForm",

  props: {
    formConfig: {
      type: Array,
      required: true,
    },

    model: {
      type: Object,
      required: true,
    },

    refForm: {
      type: String,
      required: true,
      default: 'form'
    },
  },

  methods: {
    getInput(type) {
      switch (type) {
        case 'select':
          return VSelect;
        case 'combobox':
          return VCombobox;
        case 'textfield':
          return VTextField;
        case 'autocomplete':
          return VAutocomplete;
        case 'fileinput':
          return VFileInput;
        case 'checkbox':
          return VCheckbox;
        case 'radio':
          return VRadio;
        default:
          return VTextField;
      }
    },
  },
}
</script>